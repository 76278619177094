import Big from 'big.js';
import { toDate } from 'date-fns';
import { z } from 'zod';

const datetimeOffset = z.string().datetime({ offset: true });

export const bigNumberSchema = z.preprocess(
  (val) => {
    if (typeof val === 'string' || typeof val === 'number') {
      try {
        return new Big(val);
      } catch {
        return undefined;
      }
    }

    if (val instanceof Big) {
      return val;
    }

    return undefined;
  },
  z.custom<Big>((val) => val instanceof Big, 'Cannot create Big number from input.'),
) as z.ZodEffects<z.ZodType<Big.Big, z.ZodTypeDef, Big.Big>, Big.Big, Big.Big>;

export const isoDateSchema = z.preprocess((val) => {
  const isDateTime = datetimeOffset.safeParse(val);

  if (!isDateTime.success) {
    return undefined;
  }

  return toDate(isDateTime.data).toISOString();
}, z.string().datetime()) as z.ZodEffects<z.ZodString, string, string>;
